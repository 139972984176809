import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import { Button, ListItemButton } from "@mui/material";
import DrawerManager from "./DrawerManager";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../app/configSlice";
import { useHistory } from "react-router-dom";

const drawerWidth = 240;

const featureList = [
  "Dynamic Messages",
  "Special Event",
  "Help",
  "Local Messages",
  "Misc",
];

function MainPage() {
  const [pageIndex, setPageIndex] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    console.log("use effect test");
    dispatch(updateConfig());
  }, []);

  const handleClick = (index) => {
    console.log("this is a the index ", index);
    setPageIndex(index);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" noWrap component="div">
              VP Config
            </Typography>
          </Box>
          <Box>
            <Button
              sx={{ color: "white" }}
              variant="outlined"
              onClick={() => {
                localStorage.clear();
                history.push("/login");
              }}
            >
              Log out
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {featureList.map((text, index) => (
              <ListItemButton
                key={text}
                onClick={() => {
                  handleClick(index);
                }}
              >
                <ListItemText primary={text} />
              </ListItemButton>
            ))}
          </List>
          <Divider />
          <List>
            <ListItemButton
              button
              key="Simulate"
              onClick={() => {
                handleClick(98);
              }}
            >
              <ListItemText primary="Simulate" />
            </ListItemButton>
            <ListItemButton
              button
              key="Deployment"
              onClick={() => {
                handleClick(99);
              }}
            >
              <ListItemText primary="Deployment" />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <DrawerManager page={pageIndex} />
      </Box>
    </Box>
  );
}

export default MainPage;

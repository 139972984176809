import * as React from 'react';
import { Button, Box, TextField, InputLabel, MenuItem, FormControl,
   Select, SelectChangeEvent } from "@mui/material";
import { SERVER_URL } from "../app/constant";
import { useToken } from "../auth/useToken";

function SimulatePage() {
  const [token, setToken] = useToken();
  const [radioOneStation, setStation] = React.useState("");

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const handleResetOnboarding = () => {
    fetch(`${SERVER_URL}/reset-onboard`, requestOptions)
      .then((response) => {
        console.log(response.json());
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleFirstTime = () => {
    fetch(`${SERVER_URL}/reset-first-time`, requestOptions)
    .then((response) => {
      console.log(response.json());
    })
    .catch((error) => {
      console.log(error.message);
    });
  };
  

  const handleRadioStation = () => {
    requestOptions.body = JSON.stringify({"station": radioOneStation})
    console.log(`station changed to ` + radioOneStation);
    fetch(`${SERVER_URL}/reset-station`, requestOptions)
    .then((response) => {
      console.log(response.json());
    })
    .catch((error) => {
      console.log(error.message);
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mt: 2,
        }}
      >
        <TextField
          id="outlined-multiline-static"
          label="Alexa Test Message"
          multiline
          sx={{ width: "40vw" }}
        />
        <Button variant="contained" sx={{ ml: 2, height: 58, width: 58 }}>
          Play
        </Button>
      </Box> */}
      <Button
        variant="contained"
        sx={{ width: "20vw", mt: 2 }}
        onClick={handleResetOnboarding}
      >
        Reset onboarding
      </Button>
      <Button
        variant="contained"
        sx={{ width: "20vw", mt: 2 }}
        onClick={handleFirstTime}
      >
        Reset first time experience
      </Button>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mt: 2,
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="select-label">
            Radio One Station
          </InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={radioOneStation}
            label="Radio One Station"
            sx={{ width: "20vw" }}
            onChange={(event) => {setStation(event.target.value)}}
          >
            <MenuItem value={'calgary'}>Calgary</MenuItem>
            <MenuItem value={'edmonton'}>Edmonton</MenuItem>
            <MenuItem value={'kamloops'}>Kamloops</MenuItem>
            <MenuItem value={'kelowna'}>Kelowna</MenuItem>
            <MenuItem value={'princeGeorge'}>Prince George</MenuItem>
            <MenuItem value={'vancouver'}>Vancouver</MenuItem>
            <MenuItem value={'victoria'}>Victoria</MenuItem>
            <MenuItem value={'winnipeg'}>Winnipeg</MenuItem>
            <MenuItem value={'fredericton'}>Fredericton</MenuItem>
            <MenuItem value={'moncton'}>Moncton</MenuItem>
            <MenuItem value={'saintJohn'}>Saint John</MenuItem>
            <MenuItem value={'cornerBrook'}>Corner Brook</MenuItem>
            <MenuItem value={'gooseBay'}>Goose Bay</MenuItem>
            <MenuItem value={'grandFalls'}>Grand Falls</MenuItem>
            <MenuItem value={'stJohns'}>St. John's</MenuItem>
            <MenuItem value={'inuvik'}>Inuvik</MenuItem>
            <MenuItem value={'yellowknife'}>Yellowknife</MenuItem>
            <MenuItem value={'halifax'}>Halifax</MenuItem>
            <MenuItem value={'sydney'}>Sydney</MenuItem>
            <MenuItem value={'iqaluit'}>Iqaluit</MenuItem>
            <MenuItem value={'barrie'}>Barrie</MenuItem>
            <MenuItem value={'kingston'}>Kingston</MenuItem>
            <MenuItem value={'kitchenerWaterloo'}>Kitchener-Waterloo</MenuItem>
            <MenuItem value={'london'}>London</MenuItem>
            <MenuItem value={'ottawa'}>Ottawa</MenuItem>
            <MenuItem value={'peterborough'}>Peterborough</MenuItem>
            <MenuItem value={'sudbury'}>Sudbury</MenuItem>
            <MenuItem value={'thunderBay'}>Thunder Bay</MenuItem>
            <MenuItem value={'toronto'}>Toronto</MenuItem>
            <MenuItem value={'windsor'}>Windsor</MenuItem>
            <MenuItem value={'charlottetown'}>Charlottetown</MenuItem>
            <MenuItem value={'montreal'}>Montreal</MenuItem>
            <MenuItem value={'northQuebec'}>North Quebec</MenuItem>
            <MenuItem value={'quebecCity'}>Quebec City</MenuItem>
            <MenuItem value={'regina'}>Regina</MenuItem>
            <MenuItem value={'saskatoon'}>Saskatoon</MenuItem>
            <MenuItem value={'whitehorse'}>Whitehorse</MenuItem>
          </Select>
        </FormControl>
        <Button 
          variant="contained" 
          sx={{ ml: 2, height: 58, width: 58 }}
          onClick={handleRadioStation}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}

export default SimulatePage;

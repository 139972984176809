import { Box, TextField, FormControl, FormLabel } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import {
  updateHelpMessagePlaylist,
  updateScreenHelpMessagePlaylist,
  updateHelpMessagePodcast,
  updateScreenHelpMessagePodcast,
} from "../app/configSlice";

function HelpPage() {
  const dispatch = useDispatch();
  const helpMessagePlaylist = useSelector(
    (state) => state.config.helpMessage_playlist
  );
  const helpScreenMessagePlaylist = useSelector(
    (state) => state.config.helpMessage_playlist_screen_text
  );
  const helpMessagePodcast = useSelector(
    (state) => state.config.helpMessage_podcast
  );
  const helpScreenMessagePodcast = useSelector(
    (state) => state.config.helpMessage_podcast_screen_text
  );

  return (
    <Box>
      <TextField
        id="outlined-multiline-static"
        label="Help message playlist"
        multiline
        fullWidth
        defaultValue={helpMessagePlaylist}
        onChange={(e) => dispatch(updateHelpMessagePlaylist(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Help message playlist screen text"
        multiline
        fullWidth
        sx={{ mt: 2 }}
        defaultValue={helpScreenMessagePlaylist}
        onChange={(e) =>
          dispatch(updateScreenHelpMessagePlaylist(e.target.value))
        }
      />
      <TextField
        id="outlined-multiline-static"
        label="Help message podcast"
        multiline
        fullWidth
        sx={{ mt: 2 }}
        defaultValue={helpMessagePodcast}
        onChange={(e) => dispatch(updateHelpMessagePodcast(e.target.value))}
      />
      <TextField
        id="outlined-multiline-static"
        label="Help message podcast screen text"
        multiline
        fullWidth
        sx={{ mt: 2 }}
        defaultValue={helpScreenMessagePodcast}
        onChange={(e) =>
          dispatch(updateScreenHelpMessagePodcast(e.target.value))
        }
      />
    </Box>
  );
}

export default HelpPage;
